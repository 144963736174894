import React, {useEffect, useState} from 'react';

import './settings.css'

import {api, storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";
import Loader from "../../../loading/Loader";
import {Collapse, Form, Input, message} from "antd";
import {getMeAction} from "../../../../redux/action/action";
import {useDispatch} from "react-redux";

const Settings = () => {

    const token = storage.local.get("token");
    const user = storage.local.get("user");
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        fullName: user?.fullName,
        phoneNumber: user?.phoneNumber,
    });
    const [initialValuesPassword, setInitialValuesPassword] = useState({
        newPassword: '',
        reEnteredPassword: '',
    });

    const onFinish = (values) => {
        setLoading(true);
        const formData = {
            fullName: values?.fullName,
            phoneNumber: values?.phoneNumber,
        };
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));
        axios.put(`${ip}/api/v1/users/change-userInfo`,
            formData,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res)
                // message.success("Parolingiz o'zgartirildi");
                window?.location?.reload();
                setLoading(false);
            })
            .catch((error) => {
                // console.log(error)
                message.error(error?.response?.data?.message);
            })
    }

    const onFinishFailed = (error) => {
        // console.log(error)
    }

    const onFinishPassword = (values) => {
        setLoading(true);
        const formData = {
            userId: user?.userId,
            newPassword: values?.newPassword,
            reEnteredPassword: values?.reEnteredPassword
        };
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));
        axios.put(`${ip}/api/v1/auth/reset-password`,
            formData,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                message.success("Parolingiz o'zgartirildi");
                setLoading(false);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message);
                setLoading(false);
            })
    }

    const onFinishFailedPassword = (error) => {
        // console.log(error)
    }


    const [mentorStatistics, setMentorStatistics] = useState([])
    useEffect(() => {
            axios.get(`${ip}/mentor/forAdmin/getStatistics`,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    console.log(res)
                    setMentorStatistics(res?.data?.item);
                }).catch((err) => {});
    }, []);


    return (
        <div className="settings">
            {
                loading && <Loader/>
            }
            <div className="settings_header">
                <h2>SOZLAMALAR</h2>
            </div>
            <div className="settings_body">
                <Form
                    name="basic"
                    layout="vertical"
                    initialValues={initialValues}
                    requiredMark='optional'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="settings_body_form">
                        <div className="settings_body_form_inner">
                            <div className="settings_body_form_inner_field">
                                <div className="settings_body_form_inner_field_text"><p>F.I.SH</p></div>
                                <Form.Item
                                    name="fullName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'F.I.SH ni kiriting',
                                        },
                                    ]}
                                >
                                    <Input placeholder={"Kiriting"}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="settings_body_form_inner">
                            <div className="settings_body_form_inner_field">
                                <div className="settings_body_form_inner_field_text"><p>Telefon raqam</p></div>
                                <Form.Item
                                    name="phoneNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Telefon raqamini kiriting',
                                        },
                                    ]}
                                >
                                    <Input placeholder={"+998 00 000 00 00"}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="settings_body_form_button">
                            <button type="submit">Tahrirlash</button>
                        </div>
                    </div>
                </Form>
                <Form
                    name="basic"
                    layout="vertical"
                    // initialValues={initialValuesPassword}
                    requiredMark='optional'
                    onFinish={onFinishPassword}
                    onFinishFailed={onFinishFailedPassword}
                    autoComplete="off"
                >
                    <div className="settings_body_form">
                        <div className="settings_body_form_inner">
                            <div className="settings_body_form_inner_field">
                                <div className="settings_body_form_inner_field_text"><p>Yangi parol</p></div>
                                <Form.Item
                                    name="newPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Yangi parolni kiriting`,
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password placeholder={"Kiriting"}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="settings_body_form_inner">
                            <div className="settings_body_form_inner_field">
                                <div className="settings_body_form_inner_field_text"><p>Parolni tasdiqlash</p></div>
                                <Form.Item
                                    name="reEnteredPassword"
                                    dependencies={['newPassword']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: `Tasdiqlash parolini kiriting`,
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(`Parol noto'g'ri kiritildi!`));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder={"Kiriting"}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="settings_body_form_button">
                            <button type="submit">Parolni yangilash</button>
                        </div>
                    </div>
                </Form>
                <div className="settings_body_form_inner_field_text">
                    <p>Email pochtangiz : {user?.username}</p>
                </div>

                <div className="settings_body_mentors_title"><p>MENTORLAR STATISTIKASI</p></div>
                <div className="settings_body_mentors">
                    <div className="settings_body_mentors_inner">
                        {mentorStatistics?.map((item , index)=>{
                            if (index%2==0){
                                return(
                                    <Collapse
                                        items={[{
                                            key: index,
                                            label: item?.fullName,
                                            children:
                                                <ul>
                                                    {item?.mentorStatistics?.map((x , y)=>{
                                                        return(
                                                            <li key={y}>
                                                                <p>"{x?.courseName}" (narxi {x?.price} so'm), {x?.numberOfMentorSales?.length} marta sotilgan va umumiy sotuv {(x?.numberOfMentorSales?.length)*x?.price} so'm bo'lgan, shundan {x?.numberOfReferrals?.length} tasi referal orqali bo'lganligi uchun {(x?.numberOfReferrals?.length)*100000} so'm to'langan</p>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                        },
                                        ]}
                                    />
                                )
                            }
                        })}

                    </div>
                    <div className="settings_body_mentors_inner">
                        {mentorStatistics?.map((item , index)=>{
                            if (index%2==1){
                                return(
                                    <Collapse
                                        items={[{
                                            key: index,
                                            label: item?.fullName,
                                            children:
                                                <ul>
                                                    {item?.mentorStatistics?.map((x , y)=>{
                                                        return(
                                                            <li key={y}>
                                                                <p>"{x?.courseName}" (narxi {x?.price} so'm), {x?.numberOfMentorSales?.length} marta sotilgan va umumiy sotuv {(x?.numberOfMentorSales?.length)*x?.price} so'm bo'lgan, shundan {x?.numberOfReferrals?.length} tasi referal orqali bo'lganligi uchun {(x?.numberOfReferrals?.length)*100000} so'm to'langan</p>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                        },
                                        ]}
                                    />
                                )
                            }
                        })}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Settings;