import React from 'react';
import { Space, Table, Tag } from 'antd';


import './cooperation.css';


import styled from 'styled-components';
export const TableStyles = styled(Table)`
  tbody {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({ theme }) => theme.trhover};
  }
`;

const CooperationTable = (props) => {

    const {
        data,
        setOpenModal,
        setInitialValues
    } = props;

    const sendMessage = (value) =>{
        setInitialValues({id : value?.id});
        setOpenModal(true);
    }

    const columns = [
        {
            title: 'T/R',
            dataIndex: 'key',
            // align: 'center'
            // width : 50,
        },
        {
            title: 'ID',
            dataIndex: 'userId',
            key: 'userId',
            // width: 50,
            align: 'center'
        },
        {
            title: 'KARTA EGASI',
            dataIndex: 'cardHolder',
            key: 'cardHolder',
            textAlign : 'center',
            render: (text) => <div className="cooperation_body_table_text">{text}</div>,
        },
        {
            title: 'KARTA RAQAMI',
            dataIndex: 'cardNumber',
            key: 'cardNumber',
            textAlign : 'center',
            render: (text) => <div className="cooperation_body_table_text">{text}</div>,
        },
        {
            title: 'BALANS',
            dataIndex: 'withdrawalBalance',
            key: 'withdrawalBalance',
            textAlign : 'center',
            render: (text) => <div className="cooperation_body_table_text">{text}</div>,
        },
        {
            title: 'ARIZA TURI',
            dataIndex: 'ticketType',
            key: 'ticketType',
            textAlign : 'center',
            render: (text , record) =>
                // <div className={record.ticketType == true ? "cooperation_body_table_text cooperation_body_table_text_pay" :
                // "cooperation_body_table_text cooperation_body_table_text_no_pay"}>{text}</div>
            <div className={"cooperation_body_table_text"}>
                {record?.ticketType === "PARTNER_TICKET" ? "Hamkor" : "Mentor"}
            </div>
        },
        {
            title: 'JAVOB BERISH',
            dataIndex: 'KURS',
            key: 'address',
            textAlign : 'center',
            render: (text , record) =>
                <div className="cooperation_body_table_text cooperation_body_table_text_pay"
                     onClick={()=>sendMessage(record)}>Xabar yuborish</div>
        },

        // PARTNER_TICKET
    ];

    return (
        <div>
            <TableStyles
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

export default CooperationTable;