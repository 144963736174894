import React, {useEffect, useState} from 'react';

import './cooperation.css'
import CooperationTable from "./CooperationTable";
import CooperationPagination from "./CooperationPagination";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";
import {Form, Input, message, Radio, Space} from "antd";
import modalImg from "../../../../images/gallery-add.png";
import Modal from "react-modal";
import {BsUpload} from "react-icons/bs";

const Cooperation = () => {

    const token = storage.local.get("token");
    const user = storage.local.get("user");

    const [loading, setLoading] = useState(false);

    const [tableData, setTableData] = useState([]);

    const [cooperationTotal, setcooperationTotal] = useState(tableData.length);
    const [cooperationPaginationLimit, setcooperationPaginationLimit] = useState(10);
    const [cooperationPaginationCurrent, setcooperationPaginationCurrent] = useState(1);

    const cooperationPaginationOnchange = (e = 1, option) => {
        setcooperationPaginationCurrent(e)
        setcooperationPaginationLimit(option)
    }

    const getData = () => {
        axios.get(`${ip}/api/referral/admin/getTickets`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                const newData = res?.data?.item?.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        cardHolder: item.cardHolder,
                        cardNumber: item.cardNumber,
                        withdrawalBalance: item.withdrawalBalance,
                        id: item.id,
                        ticketType: item.ticketType,
                        userId: item.userId,
                    }
                ))
                setTableData(newData);
            })
            .catch((error) => {

            })
    }
    useEffect(() => {
        getData();
    }, [])


    // img
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({id: ''});

    const [view, setView] = useState(null);
    const [imageState, setImageState] = useState({
        initial: true,
        uploaded: false,
        requested: false,
        check: false
    });
    const [img, setImg] = useState({});

    const upload = (e) => {
        if (e.target.files && e.target.files[0]) {
            setView(URL.createObjectURL(e.target.files[0]))
            setImg({...img, image: e.target.files[0]})
            setImageState({
                initial: false,
                uploaded: true,
                requested: false,
                check: true
            })
        } else {
            setView(null)
            setImageState({
                initial: true,
                uploaded: false,
                requested: false,
                check: false
            })
        }
    }
    const cencel = () => {
        setOpenModal(false);
        setImg({});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setInitialValues({id: ''});
        setLoading(false);
    }
    const onFinish = (values) => {
        if (values?.paid === true && imageState?.uploaded === false) {
            message.error("Chek rasmini yuklang !")
        } else {
            setLoading(true);
            const formData = {
                file: img.image
            }
            const fd = new FormData();
            Object.keys(formData).forEach(i => fd.append(i, formData[i]));
            axios.post(`${ip}/api/referral/admin/responseTicket/${initialValues?.id}?message=${values?.message}&paid=${values?.paid}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}})
                .then((res) => {
                    cencel();
                    getData();
                    message.success("Muaffaqqiyatli")
                }).catch((err) => {
                setLoading(false);
                message.error("Xatolik !");
            })
        }

    }
    const onFinishFailed = (error) => {
    }


    return (
        <div className="cooperation">
            <div className="cooperation_header">
                <h2>HAMKORLIK</h2>
            </div>
            <div className="cooperation_body">
                <div className="cooperation_body_table">
                    <CooperationTable
                        data={tableData}
                        setOpenModal={setOpenModal}
                        setInitialValues={setInitialValues}
                    />
                </div>
            </div>

            <Modal
                isOpen={openModal}
                onRequestClose={cencel}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="cooperation_body_modal">
                    <Form
                        name="basic"
                        layout="vertical"
                        requiredMark='optional'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className="cooperation_body_modal_inner_text">
                            <h2>Xabar yuborish</h2>
                        </div>
                        <div className="user_list_modal_uploadImg">
                            <div className="user_list_modal_uploadImg_left">
                                <div className="user_list_modal_uploadImg_left_inner">
                                    {
                                        initialValues.edit && !imageState.check ?
                                            <img src={`${ip}/api/photo/show/${initialValues.image}`}
                                                 className="img1"/>
                                            :
                                            imageState.uploaded ?
                                                <img src={view} className="img1"/>
                                                : <img src={modalImg} className="img2"/>}
                                </div>
                            </div>
                            <div className="user_list_modal_uploadImg_right">
                                <div className="user_list_modal_uploadImg_right_inner">
                                    <label htmlFor='add_staff_img' className="upload_button">
                                        <div className="upload_button_icon">
                                            {/*<img src={UploadIcon} style={{marginRight: "8px"}}/>*/}
                                            <BsUpload size={22} style={{marginRight: "8px"}}/>
                                            {"Chek yuklash"}
                                        </div>
                                        <input onChange={upload} name='image' type="file"
                                               id="add_staff_img"
                                               style={{display: 'none'}}
                                               className=""
                                        />
                                    </label>
                                    <div className="upload_button_text">
                                        <span>{"Maksimal fayl hajmi 1 Mb 500x500 o‘lchamda"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cooperation_body_modal_field">
                            <Form.Item
                                label={"Xabar"}
                                name="message"
                                rules={[{
                                    required: true, message: 'Xabarni kiriting',
                                },]}
                            >
                                <Input
                                    size="large"
                                    placeholder={"Kiriting"}
                                />
                            </Form.Item>
                        </div>
                        <div className="cooperation_body_modal_field">
                            <Form.Item
                                label="Haqiqatdan ham pul tashaldimi ?"
                                name="paid"
                                rules={[{required: true, message: 'Tanlang'}]}>
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value={true}> Ha</Radio>
                                        <Radio value={false}> Yo'q</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <div className="landing_adds_body_inner_inner_addModal_inner_buttons">
                            <button type='button' onClick={cencel}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>

        </div>
    );
};

export default Cooperation;