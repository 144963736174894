import React, {useEffect, useState} from 'react';

import caruselImg from "../../../../images/user/userDashboard/Yandex direct copy (4) 2.png";
import './certificate.css';
import './certificatePDF/certificatePDF.css';
import {Collapse, Form, Image, Input, message , Radio} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";
import CertificateTest from "./CertificateTest";

import {QRCodeSVG} from 'qrcode.react';
import QRCode from 'qrcode.react';
import Loader from "../../../loading/Loader";

import {jsPDF} from "jspdf";
import html2canvas from 'html2canvas';

import CertificatePDF from "./certificatePDF/CertificatePDF";
import Modal from "react-modal";
import globe from "./certificatePDF/img/globeorig.png";
import {MdAdd, MdDeleteOutline, MdOutlineModeEdit, MdOutlineRemoveRedEye} from "react-icons/md";
import modalImg from "../../../../images/gallery-add.png";
import {BsUpload} from "react-icons/bs";
import {FcEditImage} from "react-icons/fc";
import {RiImageEditFill} from "react-icons/ri";


const Certificate = () => {
    const token = storage.local.get("token");
    const user = storage.local.get("user");
    const [openTestPage, setOpenTestPage] = useState(false);
    const [testData, setTestData] = useState([]);
    const [certificate, setCertificate] = useState([]);


    const getCerData = () => {
        axios.get(`${ip}/api/v1/certification/getAllCertificate`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setCertificate(res?.data?.item);
            }).catch((err) => {
        })
    }
    useEffect(() => {
        getCerData()
    }, []);

    // add certificate
    const [certificateOpenModal, setcertificateOpenModal] = useState(false);
    const [certificateDeleteModal, setcertificateDeleteModal] = useState(false);
    const [initialValuescertificate, setInitialValuescertificate] = useState({
        name: ''
    });

    const cencelcertificate = () => {
        setcertificateOpenModal(false);
        setcertificateDeleteModal(false);
        setInitialValuescertificate({
            name: "",
        });
        setLoading(false);
    }
    const onFinishcertificate = (values) => {
        setLoading(true);
        if (initialValuescertificate?.edit) {
            axios.put(`${ip}/api/v1/certification/updateCertificate/${initialValuescertificate?.id}?newName=${values?.name}`,
                {},
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getCerData();
                    cencelcertificate();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/certification/createCertificate`, values,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getCerData();
                    cencelcertificate();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error("Xatolik !");
                setLoading(false);
            })
        }
    }
    const onFinishFailedcertificate = (error) => {
    }
    const editcertificate = (value) => {
        setInitialValuescertificate({
            ...value,
            edit: true
        });
        setcertificateOpenModal(true);
    }
    const deletecertificateModal = (value) => {
        setInitialValuescertificate({...value});
        setcertificateDeleteModal(true);
    }
    const deletecertificate = () => {
        setLoading(true);
        axios.delete(`${ip}/api/v1/certification/deleteCertification?certificateId=${initialValuescertificate?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                getCerData();
                cencelcertificate();
                message.info("O'chirildi")
            }).catch((err) => {
            // console.log(err)
            // message.error("Xatolik !");
            message.error(err?.response?.data?.message);
            setLoading(false);
        })
    }
    // add certificate

    // add img
    const [imgData, setImgData] = useState([]);
    const [imgOpenModal, setImgOpenModal] = useState(false);
    const [imgOpenDeleteModal, setImgOpenDeleteModal] = useState(false);
    const [initialValuesImg, setInitialValuesImg] = useState({image: '', id: ''});

    const addImg = (item) => {
        setInitialValuesImg({...item});
        setImgOpenModal(true);
    }
    const editImg = (item) => {
        setInitialValuesImg({...item, edit: true});
        setImgOpenModal(true);
    }

    const [view, setView] = useState(null);
    const [imageState, setImageState] = useState({
        initial: true,
        uploaded: false,
        requested: false,
        check: false
    });
    const [img, setImg] = useState({})
    const upload = (e) => {
        if (e.target.files && e.target.files[0]) {
            // console.log('uploaded')
            setView(URL.createObjectURL(e.target.files[0]))
            setImg({...img, image: e.target.files[0]})
            setImageState({
                initial: false,
                uploaded: true,
                requested: false,
                check: true
            })
        } else {
            setView(null)
            setImageState({
                initial: true,
                uploaded: false,
                requested: false,
                check: false
            })
        }
    }
    const cencelImg = () => {
        setImgOpenModal(false);
        setImgOpenDeleteModal(false);
        setImg({});
        setInitialValuesImg({image: '', id: ''});
        setImageState({
            initial: true,
            uploaded: false,
            requested: false,
            check: false
        });
        setLoading(false);
    }
    const onFinishImg = (values) => {
        setLoading(true);
        const formData = {
            file: imageState.uploaded ? img.image : (initialValuesImg.edit ? initialValuesImg.image : ""),
            // file: img.image
        }
        const fd = new FormData();
        Object.keys(formData).forEach(i => fd.append(i, formData[i]));

        if (initialValuesImg?.edit) {
            axios.put(`${ip}/api/v1/certification/addCoverPhoto/${initialValuesImg?.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    // console.log(res)
                    getCerData();
                    cencelImg();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // console.log(err)
                setLoading(false);
                if (imageState.uploaded === false) {
                    message.error("Yangi rasmni yukmaladingiz!");
                }
            })
        } else {
            axios.put(`${ip}/api/v1/certification/addCoverPhoto/${initialValuesImg?.id}`,
                fd,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getCerData();
                    cencelImg();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                // console.log(err)
                setLoading(false);
                message.error("Xatolik !");
            })
        }
    }
    const onFinishFailedImg = (error) => {
        // console.log(error)
    }
    // add img

    // publish
    const publishCer = (value) => {
        setLoading(true);
        axios.put(`${ip}/api/v1/certification/publish?certificateId=${value?.id}`, {},
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                getCerData();
                message.success("Muaffaqqiyatli");
                setLoading(false);
            }).catch((err) => {
            // console.log(err)
            setLoading(false);
            message.error("Xatolik !");
        })
    }
    // publish

    // add test

    const addTestModal = (item) => {
        settestOpenModal(true);
        // setInitialValuescertificate({...item})
    }

    const [initialValuestest, setInitialValuestest] = useState({
        certificateId: '',
        id: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        question: ''
    });
    const [testOpenModal, settestOpenModal] = useState(false);
    const [testOpenDeleteModal, settestOpenDeleteModal] = useState(false);

    const [value, setValue] = useState('');
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const cenceltest = () => {
        settestOpenModal(false);
        settestOpenDeleteModal(false);
        setValue('')
        setInitialValuestest({
            certificateId: '',
            id: '',
            optionA: '',
            optionB: '',
            optionC: '',
            optionD: '',
            question: ''
        });
        // setInitialValuescertificate({
        //     name: "",
        // });
        setLoading(false);
    }
    const onFinishtest = (values) => {
        setLoading(true);
        if (initialValuestest?.edit) {
            axios.put(`${ip}/api/v1/certification/updateQuestion/${initialValuestest?.id}`, values,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getTestID(initialValuescertificate)
                    getCerData();
                    cenceltest();
                    message.success("Muaffaqqiyatli o'zgartirildi")
                }).catch((err) => {
                // message.error("Xatolik !");
                message.error(err?.response?.data?.message);
                setLoading(false);
            })
        } else {
            axios.post(`${ip}/api/v1/certification/addQuestion?certificateId=${initialValuescertificate?.id}`, values,
                {headers: {'Authorization': `Bearer ${token}`}}
            )
                .then((res) => {
                    getTestID(initialValuescertificate)
                    getCerData();
                    cenceltest();
                    message.success("Muaffaqqiyatli qo'shildi")
                }).catch((err) => {
                message.error(err?.response?.data?.message);
                setLoading(false);
            })
        }
    }
    const onFinishFailedtest = (error) => {

    }
    const deletetestModal = (value) => {
        setInitialValuestest({...value});
        settestOpenDeleteModal(true);
    }
    const deletetest = () => {
        setLoading(true);
        axios.delete(`${ip}/api/v1/certification/deleteQuestion?questionId=${initialValuestest?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res)
                getTestID(initialValuescertificate);
                getCerData();
                cenceltest();
                message.info("O'chirildi");
            }).catch((err) => {
            // console.log(err)
            // message.error("Xatolik !");
            message.error(err?.response?.data?.message);
            setLoading(false);
        })
    }
    const editTest = (value) => {
        settestOpenModal(true);
        setInitialValuestest({...value, edit : true})
    }
    // add test

    // test list
    const [testListModal, settestListModal] = useState(false);
    const cenceltestList = () => {
        settestListModal(false);
        setTestData([])
        setInitialValuestest({
            certificateId: '',
            id: '',
            optionA: '',
            optionB: '',
            optionC: '',
            optionD: '',
            question: ''
        });
        setInitialValuescertificate({
            name: "",
        });
        setLoading(false);
    }

    // test list
    const getTestID = (item) => {
        setInitialValuescertificate({...item});
        axios.get(`${ip}/api/v1/certification/getAllQuestion?certificateId=${item?.id}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setTestData(res?.data?.item);
                settestListModal(true);
            }).catch((err) => {
        })
    };
    const [courseName, setCourserName] = useState('');
    const [qrCodeLink, setQrCodeLink] = useState('');
    const [loading, setLoading] = useState(false);
    const openCer = (name) => {
        setLoading(true);
        setCourserName(name);
        // setOpenCerModal(true)
    }
    useEffect(() => {
        if (courseName) {
            handleDownloadPDF();
        }
    }, [courseName]);
    const handleDownloadPDF = () => {
        const input = document.getElementById('pdf-content');
        html2canvas(input, {scale: 2}).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 1.0); // Ensure highest quality
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'SLOW'); // Use 'FAST' , 'SLOW' , 'NONE' compression for better quality
            pdf.save('certificate.pdf');

            setLoading(false);
            setCourserName('');
            setQrCodeLink('');
        });

    };

    return (
        <div className="certificate">
            {
                loading && <Loader/>
            }
            {!openTestPage ?
                <div>
                    <div className="certificate_header">
                        <h2>SERTIFIKAT</h2>
                        <button onClick={() => setcertificateOpenModal(true)}>
                            <MdAdd style={{marginRight: "5px", fontSize: "22px"}}/>Sertifikat yaratish
                        </button>
                    </div>
                    <div className="certificate_body">
                        {certificate?.map((item, index) => {
                            return (
                                <div className="certificate_body_card" key={index}>
                                    <div className="certificate_body_card_header">
                                        <Image
                                            width={"100%"}
                                            height={"100%"}
                                            src={`${ip}/api/photo/show/${item?.coverPhotoId}`}
                                            title=""
                                            preview={{
                                                mask: (<MdOutlineRemoveRedEye style={{fontSize: "22px"}}/>),
                                                maskClassName: 'customize-mask',
                                            }}
                                        />

                                        {/*<img src={`${ip}/api/photo/show/${item?.coverPhotoId}`}/>*/}
                                        {item?.coverPhotoId ?
                                            <div className="certificate_body_card_header_icons"
                                                 onClick={() => editImg(item)}>
                                                <div className="table_icons_edit">
                                                    {/*<FcEditImage />*/}
                                                    <RiImageEditFill/>
                                                    {/*<MdOutlineModeEdit/>*/}
                                                </div>
                                            </div>
                                            :
                                            <div className="certificate_body_card_header_icons"
                                                 onClick={() => addImg(item)}>
                                                <div className="table_icons_add"><MdAdd/></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="certificate_body_card_edit_delete">
                                        <div className="certificate_body_card_edit_delete_text">
                                            <h2>{item?.name}</h2>
                                        </div>

                                        <div className="certificate_body_card_body_icons">
                                            <div className="table_icons_edit" onClick={() => editcertificate(item)}>
                                                <MdOutlineModeEdit/></div>
                                            <div className="table_icons_delete"
                                                 onClick={() => deletecertificateModal(item)}><MdDeleteOutline/></div>
                                        </div>
                                    </div>
                                    <div className="certificate_body_card_body">
                                        {/*{*/}
                                        {/*    item?.isAlreadyTaken ?*/}
                                        {/*        <div className="certificate_body_card_body_button">SERTIFIKAT OLINGAN</div>*/}
                                        {/*        :*/}
                                        {/*        (*/}
                                        {/*            item?.isDone ?*/}
                                        {/*                <div className="certificate_body_card_body_button" onClick={() => openCer(item?.name)}>SERTIFIKATNI YUKLASH</div>*/}
                                        {/*                :*/}
                                        {/*                <div className="certificate_body_card_body_button" onClick={() => getTestID(item?.id)}>TESTNI BOSHLASH</div>*/}
                                        {/*        )*/}
                                        {/*}*/}
                                        <div className="certificate_body_card_body_button"
                                             onClick={() => getTestID(item)}>TESTNI KO'RISH
                                        </div>
                                        <div className="certificate_body_card_body_icons">

                                        </div>

                                        {item?.published ?
                                            <div className="certificate_body_card_edit_delete_unPublish"
                                                 onClick={() => publishCer(item)}>BEKOR QILISH</div>
                                            :
                                            <div className="certificate_body_card_edit_delete_publish"
                                                 onClick={() => publishCer(item)}>RUXSAT BERISH</div>
                                        }
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
                :
                <CertificateTest
                    testData={testData}
                    setTestData={setTestData}
                    setOpenTestPage={setOpenTestPage}
                />
            }

            {/*add certificate*/}
            <Modal
                isOpen={certificateOpenModal}
                onRequestClose={cencelcertificate}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="add_test_modal">
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={initialValuescertificate}
                        requiredMark='optional'
                        onFinish={onFinishcertificate}
                        onFinishFailed={onFinishFailedcertificate}
                        autoComplete="off"
                    >
                        <div className="add_test_modal_addModal_inner_text">
                            {initialValuescertificate?.edit ? <h2>O'zgartirish</h2> :
                                <h2>Sertifikat yaratish</h2>}
                        </div>
                        <Form.Item
                            label={"Nomi"}
                            name="name"
                            rules={[{
                                required: true, message: 'Nomini kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <div className="add_test_modal_addModal_inner_buttons">
                            <button type='button' onClick={cencelcertificate}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                isOpen={certificateDeleteModal}
                // onRequestClose={cencelVideo}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="exit_modal">
                    <div className="exit_modal_text">
                        <h2>Sertifikatni o'chirasizmi ?</h2>
                    </div>
                    <div className="exit_modal_buttons">
                        <button onClick={cencelcertificate}>Yo'q</button>
                        <button onClick={deletecertificate}>Ha</button>
                    </div>
                </div>
            </Modal>
            {/*add certificate*/}

            {/*add img*/}
            <Modal
                isOpen={imgOpenModal}
                onRequestClose={cencelImg}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="landing_adds_body_inner_inner_addModal_inner">
                    <Form
                        name="basic"
                        layout="vertical"
                        // initialValues={initialValuesVideo}
                        requiredMark='optional'
                        onFinish={onFinishImg}
                        onFinishFailed={onFinishFailedImg}
                        autoComplete="off"
                    >
                        <div className="landing_adds_body_inner_inner_addModal_inner_text">
                            {initialValuesImg?.edit ? <h2>O'zgartirish</h2> :
                                <h2>Rasm qo'shish</h2>}
                        </div>
                        <div className="user_list_modal_uploadImg">
                            <div className="user_list_modal_uploadImg_left">
                                <div className="user_list_modal_uploadImg_left_inner">
                                    {
                                        initialValuesImg.edit && !imageState.check ?
                                            <img
                                                src={`${ip}/api/photo/show/${initialValuesImg?.coverPhotoId}`}
                                                className="img1"/>
                                            :
                                            imageState.uploaded ?
                                                <img src={view} className="img1"/>
                                                : <img src={modalImg} className="img2"/>}
                                </div>
                            </div>
                            <div className="user_list_modal_uploadImg_right">
                                <div className="user_list_modal_uploadImg_right_inner">
                                    <label htmlFor='add_staff_img' className="upload_button">
                                        <div className="upload_button_icon">
                                            {/*<img src={UploadIcon} style={{marginRight: "8px"}}/>*/}
                                            <BsUpload size={22} style={{marginRight: "8px"}}/>
                                            {"Rasm yuklash"}
                                        </div>
                                        <input onChange={upload} name='image' type="file"
                                               id="add_staff_img"
                                               style={{display: 'none'}}
                                               className=""
                                        />
                                    </label>
                                    <div className="upload_button_text">
                                        <span>{"Maksimal fayl hajmi 1 Mb 500x500 o‘lchamda"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing_adds_body_inner_inner_addModal_inner_buttons">
                            <button type='button' onClick={cencelImg}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            {/*add img*/}

            {/*add test*/}
            <Modal
                isOpen={testOpenModal}
                onRequestClose={cenceltest}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="add_test_modal">
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={initialValuestest}
                        requiredMark='optional'
                        onFinish={onFinishtest}
                        onFinishFailed={onFinishFailedtest}
                        autoComplete="off"
                    >
                        <div className="add_test_modal_addModal_inner_text">
                            {initialValuestest?.edit ? <h2>Testni o'zgartirish</h2> :
                                <h2>Test qo'shish</h2>}
                        </div>
                        <Form.Item
                            label={"Savol"}
                            name="question"
                            rules={[{
                                required: true, message: 'Savolni kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"A variant"}
                            name="optionA"
                            rules={[{
                                required: true, message: 'A variantni kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"B variant"}
                            name="optionB"
                            rules={[{
                                required: true, message: "B variantni kiriting",
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"C variant"}
                            name="optionC"
                            rules={[{
                                required: true, message: 'C variantni kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"D variant"}
                            name="optionD"
                            rules={[{
                                required: true, message: 'D variantni kiriting',
                            },]}
                        >
                            <Input
                                size="large"
                                placeholder={"Kiriting"}
                            />
                        </Form.Item>
                      <div className="add_test_modal_radio">
                          {/*<p>To'g'ri javob :</p>*/}
                          <Form.Item
                              label={"To'g'ri javobni tanlang"}
                              name="correctOption"
                              rules={[{
                                  required: true, message: "To'g'ri javobni tanlang",
                              },]}
                          >
                              <Radio.Group onChange={onChange} value={value}>
                                  <Radio value={"optionA"}>A</Radio>
                                  <Radio value={"optionB"}>B</Radio>
                                  <Radio value={"optionC"}>C</Radio>
                                  <Radio value={"optionD"}>D</Radio>
                              </Radio.Group>
                          </Form.Item>
                      </div>

                        <div className="add_test_modal_addModal_inner_buttons">
                            <button type='button' onClick={cenceltest}>Bekor qilish</button>
                            <button type="submit">Saqlash</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                isOpen={testOpenDeleteModal}
                // onRequestClose={cencelVideo}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay"
                closeTimeoutMS={0}
            >
                <div className="exit_modal">
                    <div className="exit_modal_text">
                        <h2>Testni o'chirasizmi ?</h2>
                    </div>
                    <div className="exit_modal_buttons">
                        <button onClick={cenceltest}>Yo'q</button>
                        <button onClick={deletetest}>Ha</button>
                    </div>
                </div>
            </Modal>
            {/*add test*/}
            {/*test list*/}
            <Modal
                isOpen={testListModal}
                onRequestClose={cenceltestList}
                contentLabel="My dialog"
                className="myModal"
                overlayClassName="myOverlay2"
                closeTimeoutMS={0}
            >
                <div className="test_list">
                        <div className="add_test_modal_list_header">
                            <h2>TESTLAR</h2>
                        </div>
                    <div className="test_list_add">
                        <div className="test_list_add_inner" onClick={addTestModal}><MdAdd/> Test qo'shish</div>
                    </div>
                        {testData?.map((item, index) => {
                            return (
                                <div className="add_test_modal_list">
                                    <div className="add_test_modal_list_left">
                                        <p>{index + 1}. {item?.question}</p>
                                    </div>
                                    <div className="add_test_modal_list_right">
                                        <div className="table_icons_edit" onClick={() => editTest(item)}><MdOutlineModeEdit/></div>
                                        <div className="table_icons_delete" onClick={()=>deletetestModal(item)}><MdDeleteOutline/></div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="add_test_modal_list_footer">
                            <div className="add_test_modal_list_footer_button" onClick={cenceltestList}>YOPISH</div>
                        </div>
                </div>
            </Modal>
            {/*test list*/}
            <CertificatePDF
                courseName={courseName}
                qrCodeLink={qrCodeLink}
            />
        </div>
    );
};

export default Certificate;